<div class="header">
  <div class="advertisement" [style.height.px]="top">
    <!-- /272140683/adxp_hayatba_inarticle -->
    <div id='div-gpt-ad-1732793560324-0' style='min-width: 160px; min-height: 50px;'>
    </div>
  </div>

  <div class="navigation" [style.top.px]="top">
    <div class="left">
      <button class="logo"
              aria-label="home"
              [routerLink]="'/'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      </button>

      <div class="categories">
        <button *ngIf="'/najnovije' === selectedRoute; else regularButton"
                (click)="customSetCategory(); checkUrlCategory()"
                class="button selected no-hover-effect"
                [routerLink]="'najnovije'"
        >
          <span>Najnovije</span>
        </button>

        <ng-template #regularButton>
          <button (click)="customSetCategory(); checkUrlCategory()"
                  class="button regular"
                  [routerLink]="'najnovije'"
          >
            <span>Najnovije</span>
          </button>
          <!--<button (click)="customSetCategory(); checkUrlCategory()"
                  class="button regular"
                  [routerLink]="'news/najnovije/1'"
                  [style.color]="'#fff'">
            <span>Najnovine</span>
          </button>-->
        </ng-template>

        @if(categories !== undefined){
          @for (category of categories; track category){
            <ng-container [ngSwitch]="category[categoriesProperty.id]">
              <button *ngIf="category[categoriesProperty.category_url] === selectedRoute; else regularButton"
                      (click)="setCategory(category); checkUrlCategory()"
                      class="button selected no-hover-effect"
                      [routerLink]="category[categoriesProperty.category_url]"
              >

                <span>{{ category[categoriesProperty.name] }}</span>
              </button>
              <!--<button *ngIf="'news/' + category[categoriesProperty.url] + '/1' === selectedRoute; else regularButton"
                      (click)="setCategory(category); checkUrlCategory()"
                      class="button selected no-hover-effect"
                      [routerLink]="'news/' + category[categoriesProperty.url] + '/1'"
                      [style.color]="category[categoriesProperty.color_code]">

                <span>{{ category[categoriesProperty.name] }}</span>
              </button>-->
              <ng-template #regularButton>
                <button (click)="setCategory(category); checkUrlCategory()"
                        class="button regular"
                        [routerLink]="category[categoriesProperty.category_url]"
                >
                  <span>{{ category[categoriesProperty.name] }}</span>
                </button>
                <!--<button (click)="setCategory(category); checkUrlCategory()"
                        class="button regular"
                        [routerLink]="'news/' + category[categoriesProperty.url]  + '/1'"
                        [style.color]="category[categoriesProperty.color_code]">
                  <span>{{ category[categoriesProperty.name] }}</span>
                </button>-->
              </ng-template>
            </ng-container>
          }
        } @else {
          <span class="loader"></span>
        }
      </div>
      <!--      <ng-container *ngIf="tokenExists()">
              <button mat-button
                      class="button regular"
                      [routerLink]="'profile'">
                Profile
              </button>
            </ng-container>-->
    </div>

    <div class="center">
      <a href="https://www.facebook.com/HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com/hayatmediabih/" target="_blank" class="social-icon">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://twitter.com/HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="https://www.youtube.com/@HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-youtube"></i>
      </a>
    </div>

    <div class="right">
      <button class="button red"
              [routerLink]="Route.TV + Route.SEPARATOR + Route.GLEDAJ">
        <div class="red-dot"></div>
        <p>Live TV</p>
      </button>
      <button class="account-button" mat-button [matMenuTriggerFor]="menu">
        <i class="fa fa-user"></i>
      </button>
      <mat-menu #menu="matMenu">
        @if(this.isLogedIn()){
          <button mat-menu-item (click)="logoutUser()">Logout</button>

        } @else {
          <button mat-menu-item (click)="redirectToLogin()">Login</button>
        }
      </mat-menu>

    </div>
  </div>

  <!--///////////////////////////Toolbar small devices//////////////////////////////-->

  <div class="toolbar" [style.top.px]="top">
    <button class="menu-button" type="button"
            mat-button (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
    <button class="logo"
            aria-label="home"
            [routerLink]="'/'">
      <img src="assets/hayat-logo.webp">
    </button>
    <div class="live">
      <button class="account-button" mat-button [matMenuTriggerFor]="menu">
        <i class="fa fa-user"></i>
      </button>
      <mat-menu #menu="matMenu">
        @if(this.isLogedIn()){
          <button mat-menu-item (click)="logoutUser()">Logout</button>

        } @else {
          <button mat-menu-item (click)="redirectToLogin()">Login</button>
        }
      </mat-menu>
    </div>
  </div>


  <div class="sidebar" [class.active]="sidebarOpen" [style.top.px]="top">
    <div class="categories-sidebar">
      <button mat-button
              (click)="customSetCategorySidebar()"
              class="button regular"
              [routerLink]="'najnovije'">
        Najnovije
      </button>
      <ng-container *ngFor="let category of categories">
        <ng-container [ngSwitch]="category[categoriesProperty.id]">
          <button mat-button
                  (click)="setCategorySidebar(category)"
                  class="button regular"
                  [routerLink]="category[categoriesProperty.category_url]">
            {{category[categoriesProperty.name]}}
          </button>
        </ng-container>
      </ng-container>
    </div>
    <div class="hayat-tv">
      <button class="button red"
              [routerLink]="Route.TV + Route.SEPARATOR + Route.GLEDAJ"
      >
        <div class="red-dot"></div>
        <p>Live TV</p>
      </button>
    </div>
    <div class="social-media">
      <a href="https://www.facebook.com/HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com/hayatmediabih/" target="_blank" class="social-icon">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://twitter.com/HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="https://www.youtube.com/@HayatMediaBIH" target="_blank" class="social-icon">
        <i class="fab fa-youtube"></i>
      </a>
    </div>
  </div>

  <!--Toolbar small devices-->

</div>
