<div class="category">
  @if(category){
    <h2>
      {{category[categoriesProperty.name]}}
    </h2>
  }

  @if (articlesByColumn.length !== 0) {
    <div class="custom-masonry">
      @for(column of getNumberArray(numberOfColumns); track column; let id = $index){
        <div class="custom-masonry-column">
          @for(article of articlesByColumn[id]; track article){
            @if(article.video_post){
              <div class="video-newest article">
                @if(!isVideo(article.image_list?.[0]?.url)){
                  <a
                    [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
                    [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]"
                  >
                    <img src="{{ article.image_list?.[0]?.url }}" alt="{{ article[articlesProperty.title] }}">
                  </a>
<!--                  <img src="{{ article.image_list?.[0]?.url }}">-->
                } @else {
                  <video
                    #videoElement
                    [src]="article.image_list?.[0]?.url"
                    controls
                    appVideoPlayer
                    loop
                    preload
                    playsinline
                  >
                  </video>
                }
                <a
                  [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
                  [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
                  <span class="icon">
                    <i class="fa-solid fa-video"></i>
                    <span class="red-dot"></span>
                  </span>
                  {{article[articlesProperty.title]}}
                </a>
              </div>

            } @else if(article.photo_post){
              <a class="photo-newest article"
                 [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
                 [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
                <!--            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
                              <p>{{article.category.name}}</p>
                            </div>-->
                <img src="{{article.image_list?.[0]?.url}}">
                <h3>
              <span class="icon">
                <i class="fa-solid fa-camera"></i>
              </span>
                  {{article[articlesProperty.title]}}</h3>
              </a>
            }  @else if(article.text_post){
              <a class="text-newest article"
                 [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
                 [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
                <!--            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">-->
                <!--              <p>{{article.category.name}}</p>-->
                <!--            </div>-->
                <h3>
              <span class="icon">
                <i class="fa-solid fa-align-justify"></i>
              </span>
                  {{article[articlesProperty.title]}}
                </h3>
              </a>
            }
          }
        </div>
      }
    </div>
  }
</div>
