import {
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {Categories} from "../../../models/categories.model";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Articles} from "../../../models/articles.model";
import {CategoryArticles} from "../../../models/category-articles.model";
import {CategoryArticlesProperty} from "../../../models/category-articles-property.enum";
import {isPlatformBrowser, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ArticlesService} from "../../../services/articles.service";
import {Observable, windowWhen} from "rxjs";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgForOf,
    NgOptimizedImage
  ],
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit{

  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef<HTMLVideoElement>>;
  private intersectionObserver: IntersectionObserver | undefined;

  private isLoading: boolean = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isCloseToBottom() && !this.isLoading) {
      this.isLoading = true
      this.loadNewPage();
    }
  }

  private isCloseToBottom(): boolean {
    const threshold = 600; // Distance in pixels from the bottom
    const position = window.innerHeight + window.scrollY;
    const height = document.body.scrollHeight;
    return position > height - threshold;
  }

  public category!: Categories;
  public categoryString : string = '';

  public categoryArticles!: CategoryArticles;
  public articles!: Articles[];

  categoriesSubscription: any;
  articlesSubscription: any;

  page: number = 1;

  public articlesProperty = ArticlesProperty;
  public categoryArticlesProperty = CategoryArticlesProperty;
  public categoriesProperty = CategoriesProperty;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private articlesService: ArticlesService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      const innerWidth = window.innerWidth;

      if (innerWidth >= 1800) {
        this.numberOfColumns =  Math.floor(innerWidth / 400);
      }
      else {
        this.numberOfColumns = Math.min(Math.floor(innerWidth / 370), 3);
      }


      this.typesInColumns = Array.from({ length: 3 }, () => Array(this.numberOfColumns).fill(0));
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < this.numberOfColumns; j++) {
          /*By rows - video, photo, text*/
          this.typesInColumns[i][j] = 0;
        }
      }

      this.activatedRoute.params.subscribe(async params => {
        this.articlesByColumn = []
        const category = params['category']; // Assuming 'id' is the parameter name in your URL
        this.categoryString = category;
        await this.loadArticles(category);
        setTimeout(() => {
          this.muteVideos();
        }, 2000);
      })
    }
    if (this.category == undefined) {
      this.categoriesSubscription = this.activatedRoute.data.subscribe((response: any) => {
        this.category = response[ResolverResponse.CATEGORY];
      });
    }
  }

  async loadArticles(category: string){
    this.page = 1
    this.cdr.detectChanges()

    this.articlesService.getArticlesByCategoryMobile(category, this.page).subscribe(async result => {
      this.articles = result;
      await this.sortArticles();
    })

  }


  async muteVideos(){
    this.intersectionObserver = undefined
    if (this.videoElements) {
      this.videoElements.notifyOnChanges(); // Force reload of @ViewChildren
    } else {
      console.error('No video elements found.');
    }
    if (this.videoElements) {
      // After the view is initialized, access all video elements and set them to muted
      this.videoElements.forEach((videoElementRef) => {
        const videoElement = videoElementRef.nativeElement;


        // Listen for the loadeddata event before muting the video
        videoElement.addEventListener('loadeddata', () => {
          videoElement.muted = true;
          this.initializeIntersectionObserver(videoElement);
        });

        // Check if the video is already loaded (in case ngAfterViewInit is called after loading)
        if (videoElement.readyState >= 2) {
          videoElement.muted = true;
          this.initializeIntersectionObserver(videoElement);
        }
      });
    }
  }

  private initializeIntersectionObserver(videoElement: HTMLVideoElement) {
    // Initialize Intersection Observer
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If video is in viewport and loaded, play it
          videoElement.play();
        } else {
          // If video is not in viewport or not loaded, pause it
          videoElement.pause();
        }
      });
    });
    // Start observing the video element
    this.intersectionObserver.observe(videoElement);
  }

  loadNewPage(): void {
    this.page++
    if(this.page > 20) return

    this.articlesService.getArticlesByCategoryMobile(this.categoryString, this.page).subscribe(async result => {
      this.articles = result;
      await this.sortArticles();
    })
  }


  isVideo(end: string | undefined | null): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }



  /*getAd(): string{
    var randomiser = (Math.random() + 1).toString(36).substring(7);
    // @ts-ignore
    var adID = document.querySelector('#hayat_ba_450x125_left_rectangle_4_responsive').id = "hayat_ba_450x125_left_rectangle_4_responsive-" + randomiser;
    // @ts-ignore
    inView('#' + adID).once('enter', (function () {
      // @ts-ignore
      googletag.cmd.push(function () {
        if (window.innerWidth >= 1280) {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_450x125_left_rectangle_4_desktop', [[320,100],[320,50],[300,100],[300,50],[450,125]], adID).addService(googletag.pubads());
        } else {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_325x125_left_rectangle_4_mobile', [[320,100],[320,50],[300,100],[300,50],[325,125]], adID).addService(googletag.pubads());
        }
        // @ts-ignore
        googletag.display(adID);
        // @ts-ignore
        stpd.initializeAdUnit(adID);
      });
    }));

    return adID
  }*/


  typesInColumns: number[][] = [];
  public articlesByColumn: Articles[][] = [];
  numberOfColumns: number = 3;
  videoIndex = 0
  photoIndex = 1
  textIndex = 2
  getNumberArray(length: number): number[] {
    return Array.from({ length }, (_, i) => i);
  }
  async sortArticles(){
    for(let i = 0; i < this.articles?.length; i++){
      let columnControl = 0;
      if(this.articles[i][ArticlesProperty.videoPost]){
        const columnToAdArticleTo = 0
        for(let i = 0; i < this.numberOfColumns - 1; i++){
          if(this.typesInColumns[this.videoIndex][i] > this.typesInColumns[this.videoIndex][i+1]){
            columnControl = i + 1;
          }
        }
        this.typesInColumns[this.videoIndex][columnControl]++;
        if (!this.articlesByColumn[columnControl]) {
          this.articlesByColumn[columnControl] = [];
        }
        this.articlesByColumn[columnControl].push(this.articles[i])

      } else if(this.articles[i][ArticlesProperty.photoPost]){
        const columnToAdArticleTo = 0
        for(let i = 0; i < this.numberOfColumns - 1; i++){
          if(this.typesInColumns[this.photoIndex][i] > this.typesInColumns[this.photoIndex][i+1]){
            columnControl = i + 1;
          }
        }
        this.typesInColumns[this.photoIndex][columnControl]++;
        if (!this.articlesByColumn[columnControl]) {
          this.articlesByColumn[columnControl] = [];
        }
        this.articlesByColumn[columnControl].push(this.articles[i])
      } else if(this.articles[i][ArticlesProperty.textPost]){
        const columnToAdArticleTo = 0
        for(let i = 0; i < this.numberOfColumns - 1; i++){
          if(this.typesInColumns[this.textIndex][i] > this.typesInColumns[this.textIndex][i+1]){
            columnControl = i + 1;
          }
        }
        this.typesInColumns[this.textIndex][columnControl]++;
        if (!this.articlesByColumn[columnControl]) {
          this.articlesByColumn[columnControl] = [];
        }
        this.articlesByColumn[columnControl].push(this.articles[i])
      }
    }
    this.isLoading = false
  }
}
