import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input, OnChanges, OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {Images} from "../../../models/images.model";
import {isPlatformBrowser, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {NavigationEnd, RouterLink} from "@angular/router";
import {filter} from "rxjs";
import {ArticlesService} from "../../../services/articles.service";

@Component({
  selector: 'app-highlight',
  templateUrl: './highlight.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgForOf,
    NgOptimizedImage
  ],
  styleUrls: ['./highlight.component.css']
})
export class HighlightComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  articles: Articles[] = [];

  @ViewChild('videoPriority2', {static: false}) videoPlayer1!: ElementRef;
  @ViewChild('videoPriority3', {static: false}) videoPlayer2!: ElementRef;
  @ViewChild('videoPriority5', {static: false}) videoPlayer4!: ElementRef;

  private intersectionObserver!: IntersectionObserver;


  public priority1Articles: Articles[] = [];
  public priority2Articles: Articles[] = [];
  public priority3Articles: Articles[] = [];
  public priority4Articles: Articles[] = [];
  public priority5Articles: Articles[] = [];
  public priority6Articles: Articles[] = [];

  windowWidth: any
  public articlesProperty = ArticlesProperty;


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    private articlesService: ArticlesService,
    @Inject(PLATFORM_ID) private platformID: Object,
    private renderer: Renderer2,


  ) {
    /*if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      googletag.cmd.push (function () {
        if (window.innerWidth >= 1280) {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_450x125_left_rectangle_2_desktop', [[320,100],[320,50],[300,100],[300,50],[450,125]], 'hayat_ba_450x125_left_rectangle_2_responsive').addService(googletag.pubads());
        } else if (window.innerWidth >= 1024) {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_450x125_left_rectangle_2_desktop', [[320,100],[320,50],[300,100],[300,50],[450,125]], 'hayat_ba_450x125_left_rectangle_2_responsive').addService(googletag.pubads());
        } else {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_450x125_left_rectangle_2_desktop', [[320,100],[320,50],[300,100],[300,50],[450,125]], 'hayat_ba_450x125_left_rectangle_2_responsive').addService(googletag.pubads());
        }
      })

      // @ts-ignore
      stpd.que.push((function() {
        // @ts-ignore
        stpd.initialize('hayat_ba_1070x300_top_billboard_desktop');
      }));
      // @ts-ignore
      console.log(stpd)
    }*/
  }

  private filterArticlesByPriority(): void {
    this.priority1Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 1);
    this.priority2Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 2);
    this.priority3Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 3);
    this.priority4Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 4);
    this.priority5Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 5);
    this.priority6Articles = this.articles.filter(article => article[ArticlesProperty.priority] === 6);
  }

  public adID: string = ''

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
      this.cdr.detectChanges()

      this.articlesService.getArticleHighlight().subscribe(response =>{
        this.articles = response
        this.cdr.detectChanges()
        this.filterArticlesByPriority();
      })

    }
  };

  ngAfterContentChecked() {

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1732793604432-0'); });`;
      scriptElement1.text = scriptContent;
      this.renderer.setAttribute(scriptElement1, 'id', 'google-tag-script-highlight');
      this.renderer.appendChild(document.body, scriptElement1);
    }
    // After the view is initialized, access the video element and set it to muted
    if (this.videoPlayer1) {
      const videoElement1 = this.videoPlayer1.nativeElement;
      videoElement1.muted = true;

      // Listen for the loadeddata event before muting the video
      videoElement1.addEventListener('loadeddata', () => {
        this.initializeIntersectionObserver(videoElement1);
      });

      // Check if the video is already loaded (in case ngAfterViewInit is called after loading)
      if (videoElement1.readyState >= 2) {
        this.initializeIntersectionObserver(videoElement1);
      }
    }
    if (this.videoPlayer2) {
      const videoElement2 = this.videoPlayer2.nativeElement;

      // Listen for the loadeddata event before muting the video
      videoElement2.addEventListener('loadeddata', () => {
        videoElement2.muted = true;
        this.initializeIntersectionObserver(videoElement2);
      });

      // Check if the video is already loaded (in case ngAfterViewInit is called after loading)
      if (videoElement2.readyState >= 2) {
        videoElement2.muted = true;
        this.initializeIntersectionObserver(videoElement2);
      }
    }

    if (this.videoPlayer4) {
      const videoElement4 = this.videoPlayer4.nativeElement;
      videoElement4.muted = true;
    }

  }


  isVideo(end: string | undefined | null | Images): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }


  private initializeIntersectionObserver(videoElement: HTMLVideoElement) {
    // Initialize Intersection Observer
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If video is in viewport and loaded, play it
          videoElement.play();
        } else {
          // If video is not in viewport or not loaded, pause it
          videoElement.pause();
        }
      });
    });

    // Start observing the video element
    this.intersectionObserver.observe(videoElement);
  }


  ngOnDestroy() {
    // Disconnect Intersection Observer when component is destroyed
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  handleVideoClick(event: Event) {
    event.stopPropagation(); // Prevent click event from propagating to parent elements
  }

  protected readonly ArticlesProperty = ArticlesProperty;
}
