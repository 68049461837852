<div class="category">
  <div class="category-title">
    <h1>{{ category?.name }}</h1>
  </div>
  <div class="articles">
    <div class="column-1">
      @if (articles[0]) {
        <a class="priority-1 article"
             [routerLink]="['/article', articles[0][articlesProperty.url_title], articles[0][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[0][articlesProperty.url_title] + '/' + articles[0][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[0][articlesProperty.url_title] + '/' + articles[0][articlesProperty.id]">-->
          @if (articles[0].image_list && articles[0].image_list.length > 0) {
            <div class="media-container">
              @if (!isVideo(articles[0].image_list[0].url)) {
                <img ngSrc="{{articles[0].image_list[0].url}}"
                     alt="{{ articles[0].subtitle }}"
                     fetchpriority="low"
                     fill=""
                     style="position: relative"
                >
              } @else {
                <video
                  #videoElement
                  [src]="articles[0].image_list[0].url"
                  controls
                  appVideoPlayer
                  loop
                  preload="auto"
                >
                </video>
              }
            </div>
          }
          <h2>
            <span *ngIf="articles[0].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[0].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[0].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[0].title }}
          </h2>
          <p>{{ articles[0].subtitle }}</p>
        </a>

      } @else {
        <div class="loader-container">
          <span class="loader"></span>
        </div>
      }
      @if (categoryNo === 0) {
        <!-- /272140683/adxp_hayatba_homepage_category_bih -->
        <div id='div-gpt-ad-1732792666883-0' style='min-width: 160px; min-height: 50px; display: flex; justify-content: center;'>
        </div>
      } @else if( categoryNo === 3) {
        <!-- /272140683/adxp_hayatba_homepage_category_crnahronika -->
        <div id='div-gpt-ad-1732792976514-0' style='min-width: 160px; min-height: 50px; display: flex; justify-content: center;'>
        </div>
      } @else if( categoryNo === 6) {
        <!-- /272140683/adxp_hayatba_homepage_category_biznis -->
        <div id='div-gpt-ad-1732793150270-0' style='min-width: 160px; min-height: 50px; display: flex; justify-content: center;'>
        </div>
      } @else if( categoryNo === 9) {
        <!-- /272140683/adxp_hayatba_homepage_category_ostalo -->
        <div id='div-gpt-ad-1732793468940-0' style='min-width: 160px; min-height: 50px; display: flex; justify-content: center;'>
        </div>
      }
    </div>

    <div class="column-2">
      @if (articles[1]) {
        <a class="priority-2 article"
             [routerLink]="['/article', articles[1][articlesProperty.url_title], articles[1][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[1][articlesProperty.url_title] + '/' + articles[1][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[1].url_title+ '/' + articles[1][articlesProperty.id]">-->

          @if (articles[1].image_list && articles[1].image_list.length > 0) {
            <div class="media-container">
              @if (!isVideo(articles[1].image_list[0].url)) {
                <img ngSrc="{{articles[1].image_list[0].url}}"
                     alt="{{ articles[1].subtitle }}"
                     fetchpriority="low"
                     fill=""
                     style="position: relative"
                >
              } @else {
                <video
                  #videoElement
                  [src]="articles[1].image_list[0].url"
                  controls
                  appVideoPlayer
                  loop
                  preload
                  playsinline
                >
                </video>
              }
            </div>
          }
          <div class="text-box">
            <h3>
              <span *ngIf="articles[1].video_post" class="icon"><i class="fa-solid fa-video"></i><span
                class="red-dot"></span></span>
              <span *ngIf="articles[1].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
              <span *ngIf="articles[1].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
              {{ articles[1].title }}
            </h3>
            <p>{{ articles[1].subtitle }}</p>
          </div>
        </a>

      }
      <div class="other-articles">
        <a *ngIf="articles[6]" class="priority-4 article"
             [routerLink]="['/article', articles[6][articlesProperty.url_title], articles[6][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[6][articlesProperty.url_title] + '/' + articles[6][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[6].url_title+ '/' + articles[6][articlesProperty.id]">-->
          <h3>
            <span *ngIf="articles[6].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[6].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[6].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[6].title }}</h3>
        </a>
        <div class="line"></div>
        <a *ngIf="articles[7]" class="priority-4 article"
             [routerLink]="['/article', articles[7][articlesProperty.url_title], articles[7][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[7][articlesProperty.url_title] + '/' + articles[7][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[7].url_title+ '/' + articles[7][articlesProperty.id]">-->
          <h3>
            <span *ngIf="articles[7].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[7].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[7].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[7].title }}</h3>
        </a>
        <div class="line"></div>
      </div>
    </div>
    <div class="column-3">
      <div class="other-articles">
        <a *ngIf="articles[4]" class="priority-4 article"
             [routerLink]="['/article', articles[4][articlesProperty.url_title], articles[4][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[4][articlesProperty.url_title] + '/' + articles[4][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[4].url_title+ '/' + articles[4][articlesProperty.id]">-->
          <h3>
            <span *ngIf="articles[4].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[4].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[4].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[4].title }}</h3>
        </a>
        <div class="line"></div>
        <a *ngIf="articles[5]" class="priority-4 article"
             [routerLink]="['/article', articles[5][articlesProperty.url_title], articles[5][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[5][articlesProperty.url_title] + '/' + articles[5][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[5].url_title+ '/' + articles[5][articlesProperty.id]">-->
          <h3>
            <span *ngIf="articles[5].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[5].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[5].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[5].title }}</h3>
        </a>
        <div class="line"></div>
        <a *ngIf="articles[3]" class="priority-3 article"
             [routerLink]="['/article', articles[3][articlesProperty.url_title], articles[3][articlesProperty.id]]"
             [attr.href]="'/article/' + articles[3][articlesProperty.url_title] + '/' + articles[3][articlesProperty.id]">
<!--             [routerLink]="'article/' + articles[3].url_title+ '/' + articles[3][articlesProperty.id]">-->
          <div *ngIf="articles[3].image_list && articles[3].image_list.length > 0" class="media-container">
            @if(!isVideo(articles[3].image_list[0].url)){
              <img ngSrc="{{articles[3].image_list[0].url}}"
                   alt="{{ articles[3].subtitle }}"
                   fetchpriority="low"
                   fill=""
                   style="position: relative"
              >
            } @else {
              <video
                #videoElement
                width="205px"
                height="115px"
                [src]="articles[3].image_list[0].url"
                appVideoPlayer
                loop
                preload="auto"
              >
              </video>
            }
          </div>
          <div class="text-box">
            <h3>
              <span *ngIf="articles[3].video_post" class="icon"><i class="fa-solid fa-video"></i><span
                class="red-dot"></span></span>
              <span *ngIf="articles[3].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
              <span *ngIf="articles[3].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
              {{ articles[3].title }}</h3>
          </div>
        </a>
      </div>
      <a *ngIf="articles[2]" class="priority-2 article"
           [routerLink]="['/article', articles[2][articlesProperty.url_title], articles[2][articlesProperty.id]]"
           [attr.href]="'/article/' + articles[2][articlesProperty.url_title] + '/' + articles[2][articlesProperty.id]">
<!--           [routerLink]="'article/' + articles[2].url_title+ '/' + articles[2][articlesProperty.id]">-->
        <div *ngIf="articles[2].image_list && articles[2].image_list.length > 0" class="media-container">
          <img *ngIf="!isVideo(articles[2].image_list[0].url); else video"
               ngSrc="{{articles[2].image_list[0].url}}"
               alt="{{ articles[2].subtitle }}"
               fill
          >
          <ng-template #video>
            <video
              #videoElement
              width="250px"
              height="140px"
              [src]="articles[2].image_list[0].url"
              appVideoPlayer
              loop
              preload="auto"
            >
            </video>
          </ng-template>
        </div>
        <div class="text-box">
          <h3>
            <span *ngIf="articles[2].video_post" class="icon"><i class="fa-solid fa-video"></i><span
              class="red-dot"></span></span>
            <span *ngIf="articles[2].photo_post" class="icon"><i class="fa-solid fa-camera"></i></span>
            <span *ngIf="articles[2].text_post" class="icon"><i class="fa-solid fa-align-justify"></i></span>
            {{ articles[2].title }}</h3>
          <p>{{ articles[2].subtitle }}</p>
        </div>
      </a>
    </div>
  </div>
</div>
