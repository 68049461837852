<div class="contact">
  <h1>
    Kontakt
  </h1>

  <p><strong>Elvir Švrakić, CEO</strong></p>


  <p><a href="mailto:elvir@hayat.ba">elvir&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Alma Švrakić, Direktorica programa</strong></p>


  <p><a href="mailto:alma.svrakic@hayat.ba">alma.svrakic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Senad Omerašević, Direktor IPP</strong></p>


  <p><a href="mailto:senad.omera%C5%A1evi%C4%87@hayat.ba">senad.omerašević&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Sanela Junuzović, Kupovina programa (Programme Acquisition)</strong></p>


  <p><a href="mailto:sanela.junuzovic@hayat.ba" data-type="mailto" data-id="mailto:sanela.junuzovic@hayat.ba">sanela.junuzovic&#64;hayat.ba</a>
  </p>


  <p>+387 33 492 900</p>


  <p><strong>Dika Bektić, Urednica Hayat PLUS</strong></p>


  <p><a href="mailto:dika.bektic@hayat.ba">dika.bektic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Amir Saletović, Urednik digitalnih medija</strong></p>


  <p><a href="mailto:amir.saletovic@hayat.ba">amir.saletovic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Fatima Guska, šef prodaje</strong></p>


  <p><a href="mailto:fatima.guska@hayat.ba">fatima.guska&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Nermin Karačić, Direktor IT</strong></p>


  <p><a href="mailto:nermin@hayat.ba">nermin&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Anel Mulaosmanović, Šef tehnike</strong></p>


  <p><a href="mailto:anel.mulaosmanovic@hayat.ba">anel.mulaosmanovic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Jadran Ibrulj, režiser</strong></p>


  <p><a href="mailto:jadran.ibrulj@hayat.ba">jadran.ibrulj&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Nazifa Karić, Direktorica računovodstva</strong></p>


  <p><a href="mailto:nazifa.karic@hayat.ba">nazifa.karic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Ahmed Švrakić, Direktor Hayat Production</strong></p>


  <p><a href="mailto:ahmed.svrakic@hayat.ba">ahmed.svrakic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Bojan Zec Filipović, PR manager</strong></p>


  <p><a href="mailto:bojan.filipovic@hayat.ba">bojan.filipovic&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Dženan Priganica, Šef službe obrade programa</strong></p>


  <p><a href="mailto:dzenan.priganica@hayat.ba">dzenan.priganica&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Igor Todorovac, Head of Digital Media</strong></p>


  <p><a href="mailto:igor.todorovac@hayat.ba">igor.todorovac&#64;hayat.ba</a></p>


  <p>+387 33 492 900</p>


  <p><strong>Korisnička podrška</strong></p>


  <p><a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a></p>


  <p>+387 33 492 942</p>


  <p><strong>Pretplatnički servis</strong></p>


  <p><a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a></p>


  <p>+387 33 492 942</p>


  <p><strong>SNG &amp; UPLINKS</strong></p>


  <p><a href="mailto:hayatsat@hayat.ba">hayatsat&#64;hayat.ba</a></p>


  <p>+387 33 492 942</p>


  <p>Redakcija INFORMATIVNOG programa <a href="mailto:producenti@hayat.ba">producenti&#64;hayat.ba</a>&nbsp;&nbsp;</p>


  <p>Redakcija ZABAVNOG programa <a href="mailto:alma.svrakic@hayat.ba">alma.svrakic&#64;hayat.ba</a></p>


  <p>Redakcija MUZIČKOG programa <a href="mailto:amela.gadzo@hayat.ba">amela.gadzo&#64;hayat.ba</a>&nbsp;&nbsp;</p>


  <p>Redakcija DIGITALNIH medija <a href="mailto:digitala@hayat.ba">digitala&#64;hayat.ba</a>&nbsp;&nbsp;&nbsp;</p>


  <p>Redakcija emisije ISPUNI MI ŽELJU <a href="mailto:imz@hayat.ba">imz&#64;hayat.ba</a>&nbsp;&nbsp;&nbsp;</p>


  <p>Slučajevi X <a href="mailto:slucajevix@hayat.ba">slucajevix&#64;hayat.ba</a>&nbsp;&nbsp;&nbsp;</p>


  <p>Hayat PLUS <a href="mailto:sanela.poricanin@hayat.ba">sanela.poricanin&#64;hayat.ba</a>&nbsp;&nbsp;</p>


  <p>Hayat MUSIC <a href="mailto:prprodukcija@hayat.ba">prprodukcija&#64;hayat.ba</a>&nbsp;&nbsp;</p>


  <p>Hayat FOLK <a href="mailto:prprodukcija@hayat.ba">prprodukcija&#64;hayat.ba</a>&nbsp;&nbsp;&nbsp;</p>


  <p>Hayatovci <a href="mailto:hayatovci@hayat.ba">hayatovci&#64;hayat.ba</a>&nbsp;&nbsp;</p>

</div>
