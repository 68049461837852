<app-header></app-header>
<router-outlet></router-outlet>
<div class="adxp-sticky" style="left: 50%; transform: translate(-50%, 0);text-align: center;position: fixed;z-index: 999999999;bottom: 0px; transition: height 0.3s; width: 100%;">
  <script>
    var lastScrollTop = window.pageYOffset || document.documentElement.scrollTop; var adxp_sticky = document.querySelector('.adxp-sticky');
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); var adToggleHeight=30;
    var isItClosed = false;
    if (isMobile) {
      window.addEventListener("scroll", function(){
        adToggleHeight=150;
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if(isItClosed != true){
          if (st > lastScrollTop){
            adxp_sticky.style.height = adToggleHeight+'px';adxp_sticky.firstElementChild.innerHTML  = 'X';
          } else {
            adxp_sticky.style.height = adxp_sticky.children[2].offsetHeight+'px'; adxp_sticky.firstElementChild.innerHTML  = 'X';
          }
          lastScrollTop = st <= 0 ? 0 : st; adxp_sticky.style.display=adxp_sticky.children[2].style.display
        }
      }, false);
    }
    function toggleSticky(e){
      if(e.parentElement.clientHeight<adToggleHeight+10){
        e.parentElement.style.height = adxp_sticky.children[2].offsetHeight+'px';
        e.innerHTML  = '▼';
      }
      else{
        e.parentElement.style.height = adToggleHeight+'px';
        e.innerHTML  = 'X';
      }
    }
  </script>
  <span onclick="let elementAdxp = document.querySelector('.adxp-sticky');
    if(elementAdxp){elementAdxp.style.display = 'none'; isItClosed = true; this.parentElement.style.display = 'none'; } " style="color: black; width:30px; height:30px; position: absolute; top:-35px; cursor: pointer; right:2px; background: rgba(255, 255, 255, 1); display: flex; align-items: center; justify-content: center; line-height: 1; border: 1px solid black; border-radius: 50%; font-weight: bolder; font-size: larger;">&#10006;</span>

  <!-- /272140683/adxp_hayatba_sticky -->
  <div id='div-gpt-ad-1732793710895-0' style='min-width: 250px; min-height: 50px;'>
  </div>
</div>
<app-footer> </app-footer>
