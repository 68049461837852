import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Articles} from "../../../../models/articles.model";
import {Categories} from "../../../../models/categories.model";
import {Images} from "../../../../models/images.model";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {isPlatformBrowser, NgIf, NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-home-category-three',
  templateUrl: './home-category-three.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    RouterLink
  ],
  styleUrls: ['./home-category-three.component.css']
})
export class HomeCategoryThreeComponent  implements OnInit, AfterViewInit{

  @Input()
  articles!: Articles[];
  @Input()
  category: Categories | undefined;
  @Input()
  categoryNo: number | undefined;

  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  public articlesProperty = ArticlesProperty;

  public adID: string = ''

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object)
  { }

  ngOnInit(): void {

  }

  private isScriptLoaded = false;

  ngAfterViewInit() {
    // After the view is initialized, access all video elements and set them to muted
    this.videoElements.forEach((videoElement) => {
      videoElement.nativeElement.muted = true;
    });

    if (isPlatformBrowser(this.platformID)) {
      this.loadGoogleTagScript(this.categoryNo);
    }
  }

  loadGoogleTagScript(category: number | undefined) {
    if (this.isScriptLoaded) {
      return; // Exit if the script has already been loaded
    }

    const existingScript = document.getElementById('google-tag-script');
    if (existingScript) {
      this.isScriptLoaded = true;
      return; // Exit if the script is already in the DOM
    }

    const script = document.createElement('script');
    script.id = 'google-tag-script';
    script.type = 'text/javascript';
    if(category === 2){
      script.innerHTML = `
        googletag.cmd.push(function() {
          googletag.display('div-gpt-ad-1732792940546-0');
        });
      `;
      document.getElementById('div-gpt-ad-1732792940546-0')?.appendChild(script);
    } else if (category === 5){
      script.innerHTML = `
        googletag.cmd.push(function() {
          googletag.display('div-gpt-ad-1732793075608-0');
        });
      `;
      document.getElementById('div-gpt-ad-1732793075608-0')?.appendChild(script);
    } else if (category === 8){
      script.innerHTML = `
        googletag.cmd.push(function() {
          googletag.display('div-gpt-ad-1732793432388-0');
        });
      `;
      document.getElementById('div-gpt-ad-1732793432388-0')?.appendChild(script);
    }

    this.isScriptLoaded = true; // Mark the script as loaded
  }


  isVideo(end: string | undefined | null | Images): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

}
