<div class="answer">
  @if(teamId === '1'){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/tim31.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @if(teamId === '2'){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/tim32.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }

<!--  <video autoplay muted loop @fadeInOut>-->
<!--    <source src="assets/tim1.mp4" type="video/mp4">-->
<!--    Your browser does not support the video tag.-->
<!--  </video>-->
  @if(answer === 1 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_1.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 2 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_2.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 3 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_3.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  @else if(answer === 4 && display){
    <video autoplay muted loop @fadeInOut>
      <source src="assets/LOOP_4.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
</div>
